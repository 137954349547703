<template>
  <!-- :title="title" -->
  <card v-if="is_local" class="main_data_table_pagination_container yes_yes">
    <!-- For Languages Table  -->
    <div
      class="col-12 d-flex justify-content-end pb-2"
      :class="{ 'flex-row-reverse': isRtl() }"
    >
      <slot name="action"></slot>
    </div>
    <el-table
      odd
      stripe
      style="width: 100%"
      @sort-change="setLocalSort"
      :data="pagedData"
      :row-class-name="class_name"
      :cell-class-name="class_name"
      v-loading="loading"
      :class="{ table_with_rtl_direction: isRtl() }"
    >
      <slot name="columns"></slot>
    </el-table>
    <div
      slot="footer"
      class="
        col-12
        d-flex
        align-items-center
        justify-content-center justify-content-sm-between
        flex-wrap
        pt-2
      "
      :class="{ footer_with_rtl_direction: isRtl() }"
    >
      <div class="d-flex">
        <div class="d-flex handler_showing_filters_with_rtl">
          <span style="color: #9a9a9a; font-size: 0.75rem" class="mr-1">{{
            localization("Show")
          }}</span>
          <el-select
            class="select-default mb-3"
            style="width: 100px"
            :label="`${localization('Show')}`"
            v-model="pagination.perPage"
            :placeholder="`${localization('Per page')}`"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <span
            style="
              color: #9a9a9a;
              font-size: 0.75rem;
              margin-right: 10px;
              line-height: 2rem;
            "
            class="ml-1"
            >{{ localization("Entries") }}</span
          >
          <p style="line-height: 2.5" class="card-category">
            {{ localization("Showing") }} {{ from + 1 }}
            {{ localization("to") }} {{ to }} {{ localization("of") }}
            {{ count }} {{ localization("Entries") }}
          </p>
        </div>
      </div>

      <l-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="count"
      >
      </l-pagination>
    </div>
  </card>
  <card v-else class="main_data_table_pagination_container">
    <!-- for tables other than languages table  -->
    <div>
      <div
        class="d-flex justify-content-end pb-2"
        :class="{ 'flex-row-reverse': isRtl() }"
        v-if="action"
      >
        <el-dropdown @command="handleCommand">
          <l-button type="info" style="font-size: 1rem">
            <!-- Normal Import  -->
            {{
              progress_count && progress_count != 100
                ? `${Math.ceil(progress_count)} %`
                : ""
            }}

            {{ localization("Actions")
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </l-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="!hidden_export_import && hasPermission(admin_permission)"
              :command="Export"
            >
              <el-icon class="el-icon-download"></el-icon>
              {{ localization("Export") }}</el-dropdown-item
            >
            <el-dropdown-item>
              <el-upload
                v-if="import_url && hasPermission(admin_permission)"
                :show-file-list="false"
                :on-success="Refresh"
                accept=".xlsx"
                :on-progress="progress"
                :action="import_src"
                :on-error="handleErrorUpload"
              >
                <el-icon class="el-icon-upload"></el-icon>
                {{ localization("Import") }}
              </el-upload></el-dropdown-item
            >
            <el-dropdown-item
              v-if="allow_bulk_process && hasPermission(admin_permission)"
              :command="Export_Bulk"
            >
              <el-icon class="el-icon-download"></el-icon>
              {{ localization("Bulk Temp") }}
            </el-dropdown-item>
            <el-dropdown-item
              :command="emitImportBulk"
              v-if="allow_bulk_process && hasPermission(admin_permission)"
            >
              <el-icon class="el-icon-upload"></el-icon>
              {{ localization("Import Bulk") }}
            </el-dropdown-item>
            <el-dropdown-item v-if="hasPermission(edit_permission)">
              <slot name="action"></slot>
            </el-dropdown-item>
            <el-dropdown-item v-if="hasPermission(edit_permission)">
              <slot name="another_action"></slot>
            </el-dropdown-item>
            <el-dropdown-item v-if="hasPermission(edit_permission)">
              <slot name="another_action_two"></slot>
            </el-dropdown-item>
            <el-dropdown-item v-if="hasPermission(edit_permission)">
              <slot name="push_career_tasks"></slot>
            </el-dropdown-item>
            <el-dropdown-item v-if="hasPermission(edit_permission)">
              <slot name="print_institution_users"></slot>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- //? Start collapse filter  -->
      <el-collapse
        v-if="!isGroup"
        class="w-100  main_collapse_data_pagination_wrapping"
      >
        <el-collapse-item
          name="1"
          :class="{ collapse_with_rtl_direction: isRtl() }"
        >
          <template #title>
            <div
              class="wrapper_for_search_and_filter d-flex justify-content-between align-items-center w-100"
            >
              <p class="mb-0" v-if="!isContact">
                {{ localization("Filter") }} &amp; {{ localization("Search") }}
              </p>
              <p class="mb-0" v-if="isContact">{{ localization("Search") }}</p>
              <l-button type="info" class="p-2 mr-3 mb-0" @click="resetFunction"
                ><i class="el-icon el-icon-refresh"></i
              ></l-button>
            </div>
          </template>
          <!-- <el-form-item label="Expiration date" prop="expiration_date">
                <el-date-picker
                  v-model="institution.expiration_date"
                  format="dd / MM / yyyy"
                  value-format="yyyy-MM-dd"
                  :picker-options="datePickerOptions"
                >
                </el-date-picker>
              </el-form-item> -->
          <!-- //? start  logs filter -->
          <el-row :gutter="10" class="w-100 m-0">
            <el-col :md="12">
              <el-input
                ref="searchInput"
                class="mt-2 d-block d-lg-inline-block w-100"
                :placeholder="`${localization('Search')}`"
                v-model="search"
                @keyup.enter.native="Refresh(true)"
              />
            </el-col>
            <el-col :md="12">
              <!-- will not be displayed at Task table since no active and inactive  -->
              <el-select
                v-if="!hidden_export_import"
                v-model="removed"
                :placeholder="`${localization('Select status')}`"
                class="select-default mt-2 w-100"
              >
                <el-option
                  v-for="(state, i) in status"
                  :key="i"
                  :label="state.label"
                  :value="state.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col v-if="isFilterLogs" :md="12" class="mt-3">
              <el-date-picker
                v-model="filter.date_from"
                :placeholder="`${localization('Date from')}`"
                format="dd / MM / yyyy"
                value-format="yyyy-MM-dd"
                type="date"
              >
              </el-date-picker>
            </el-col>
            <el-col :md="12" v-if="isFilterLogs" class="mt-3">
              <el-date-picker
                v-model="filter.date_to"
                :placeholder="`${localization('Date to')}`"
                format="dd / MM / yyyy"
                value-format="yyyy-MM-dd"
                type="date"
              >
              </el-date-picker>
              <!-- :picker-options="datePickerOptions" -->
              <!-- :picker-options="datePickerOptions" -->
            </el-col>
            <el-col v-if="isFilterLogs" :md="12" class="mt-2">
              <el-select
                v-model="filter.action"
                :placeholder="`${localization('Select action')}`"
                class="select-default mt-2 w-100"
              >
                <el-option
                  v-for="(act, i) in $store.getters['logs/get_action_logs']"
                  :key="i"
                  :label="act"
                  :value="act"
                ></el-option>
              </el-select>
            </el-col>
            <!-- take full width at all tables but not tasks table  -->
            <el-col :md="!hidden_export_import ? 24 : 12">
              <slot name="filter"></slot>
            </el-col>
          </el-row>
          <!-- //? end  logs filter -->
        </el-collapse-item>
      </el-collapse>
      <!-- //? End collapse filter  -->

      <el-tabs
        v-if="isContact"
        v-model="request_type"
        :class="{ tabs_for_contacts_with_rtl: isRtl() }"
      >
        <el-tab-pane :label="`${localization('My Contacts')}`" name="1">
          <el-table
            odd
            stripe
            fit
            @sort-change="sortChange"
            @selection-change="selection_change"
            :cell-class-name="class_name"
            style="width: 100%"
            :data="is_local ? pagedData : DataItems"
            v-loading="isLoading || fileLoading"
            :class="{ table_with_rtl_direction: isRtl() }"
          >
            <slot name="columns"></slot>
            <!-- <slot name="columns" v-if="!isMediumScreen"></slot>

        <slot name="columnsSmallScreens" v-else></slot> -->
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="`${localization('Pending Requests')}`" name="2">
          <el-table
            odd
            stripe
            fit
            @sort-change="sortChange"
            @selection-change="selection_change"
            :cell-class-name="class_name"
            style="width: 100%"
            :data="is_local ? pagedData : DataItems"
            v-loading="isLoading || fileLoading"
            :class="{ table_with_rtl_direction: isRtl() }"
          >
            <slot name="columns"></slot>
            <!-- <slot name="columns" v-if="!isMediumScreen"></slot>

        <slot name="columnsSmallScreens" v-else></slot> -->
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="`${localization('Received Requests')}`" name="3">
          <el-table
            odd
            stripe
            fit
            @sort-change="sortChange"
            @selection-change="selection_change"
            :cell-class-name="class_name"
            style="width: 100%"
            :data="is_local ? pagedData : DataItems"
            v-loading="isLoading || fileLoading"
            :class="{ table_with_rtl_direction: isRtl() }"
          >
            <slot name="columns"></slot>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <el-table
        v-else
        odd
        stripe
        fit
        @sort-change="sortChange"
        @selection-change="selection_change"
        :cell-class-name="class_name"
        style="width: 100%"
        :data="is_local ? pagedData : DataItems"
        v-loading="isLoading || fileLoading"
        :class="{ table_with_rtl_direction: isRtl() }"
      >
        <slot name="columns"></slot>
        <!-- <slot name="columns" v-if="!isMediumScreen"></slot>

        <slot name="columnsSmallScreens" v-else></slot> -->
      </el-table>
    </div>
    <!-- //? Start footer  -->
    <div
      v-if="!isGroup"
      slot="footer"
      class="
        col-12
        d-flex
        align-items-center
        justify-content-center justify-content-sm-between
        flex-wrap
        pt-2
        data_table_pagination_footer
      "
      :class="{ footer_with_rtl_direction: isRtl() }"
    >
      <div class="d-flex">
        <div
          class="d-flex showing_numbers_container handler_showing_filters_with_rtl"
        >
          <span style="color: #9a9a9a; font-size: 0.75rem" class="mr-1 mt-1">{{
            localization("Show")
          }}</span>
          <el-select
            class="select-default mb-3"
            style="width: 100px"
            :label="`${localization('Show')}`"
            v-model="pagination.perPage"
            :placeholder="`${localization('Per page')}`"
          >
            <!-- :class="{
                'd-none':
                  ($route.name != 'Users' && item == 500) ||
                  ($route.name != 'Users' && item == 1000)
              }" -->
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <span
            style="color: #9a9a9a; font-size: 0.75rem; margin-right: 10px"
            class="ml-1 mt-1"
            >{{ localization("Entries") }}</span
          >
          <p style="line-height: 2.7" class="card-category card_numbers_text">
            {{ localization("Showing") }} {{ from + 1 }}
            {{ localization("to") }} {{ to }} {{ localization("of") }}
            {{ count }} {{ localization("Entries") }}
          </p>
        </div>
      </div>

      <l-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="count"
      >
      </l-pagination>
    </div>
    <!-- //? End footer  -->
  </card>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Swal from "sweetalert2";
export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  name: "DataTable",
  props: {
    hidden_pagination: { default: false, type: Boolean },
    DataItems: Array,
    import_url: String,
    filter: Object,
    class_name: String,
    action: {
      default: true,
      type: Boolean
    },
    title: String,
    name: String,
    loading: Boolean,
    count: Number,
    is_local: {
      default: false,
      type: Boolean
    },
    hidden_export_import: {
      default: false,
      type: Boolean
    },
    fileLoading: Boolean,
    allow_bulk_process: Boolean,
    admin_permission: String,
    edit_permission: String,
    isContact: {
      type: Boolean,
      required: false
    },
    isGroup: {
      type: Boolean,
      required: false
    },
    isFilterLogs: {
      type: Boolean,
      required: false
    }
    // request_type: {
    //   type: String,
    //   default: "1"
    // }
  },
  data() {
    return {
      request_type: "1",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 500, 1000]
      },
      sort: { column: "id", asc: false },
      locallySortedData: [],
      locallySorted: false,
      progress_count: null,
      status: [
        { value: 1, label: `Inactive` },
        { value: 0, label: `Active` }
      ],
      search: null,
      removed: 0,
      //? logs
      // date_from: null,
      // date_to: null,
      // action: null,
      // action_log: null,
      isLoading: true,
      isMediumScreen: false, // used to display content of tables depending on screens width
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        }
      }
    };
  },
  computed: {
    import_src() {
      return `${process.env.VUE_APP_BASE_URL}/${this.import_url}`;
    },
    pagedData() {
      return this.DataItems.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.count;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  mounted() {
    let query = {
      limit: this.pagination.perPage - 1,
      offset: 0
      // order_by: this.sort.column ? this.sort.column : "id",
      // order_direction: this.sort.asc ? "ASC" : "DESC",
    };
    this.$emit("Refresh", query);
    // console.log(this.$store.getters["logs/get_action_logs"]);
    // set isMediumScreen to false if screen is large size or more
    // this.isMediumScreen = window.innerWidth <= 768;

    // this.$refs["searchInput"].addEventListener("keyup", (e) => {
    //   if (e.key == "Enter" && this.search.length > 0) {
    //     this.Refresh(true);
    //   }
    // });
  },

  watch: {
    "pagination.currentPage": function() {
      if (!this.is_local) this.Refresh();
    },
    "pagination.perPage": function() {
      this.Refresh();
    },
    "filter.organization_id": function() {
      this.Refresh();
    },
    "filter.language": function() {
      this.Refresh();
    },
    "filter.type": function() {
      this.Refresh();
    },
    "filter.age_group_id": function() {
      this.Refresh();
    },
    "filter.pattern": function() {
      this.Refresh();
    },
    "filter.classification": function() {
      this.Refresh();
    },
    "filter.topic_id": function() {
      this.Refresh();
    },
    "filter.age_group": function() {
      this.Refresh();
    },
    "filter.category": function() {
      this.Refresh();
    },
    "filter.priority": function() {
      this.Refresh();
    },
    request_type: function() {
      this.Refresh();
    },
    removed: function() {
      this.Refresh();
    },
    // //? new filter in logs
    "filter.date_from": function() {
      this.Refresh();
    },
    "filter.date_to": function() {
      this.Refresh();
    },
    "filter.action": function() {
      this.Refresh();
    },

    "filter.lci": function() {
      this.Refresh();
    },
    "filter.selected_attributes": function() {
      this.Refresh();
    },
    "filter.team_member": function() {
      this.Refresh();
    },
    "filter.tasks": function() {
      this.Refresh();
    },
    "filter.work_tasks": function() {
      this.Refresh();
    },
    "filter.career_tasks": function() {
      this.Refresh();
    },
    // date_from: function() {
    //   this.Refresh();
    // },
    // date_to: function() {
    //   this.Refresh();
    // },
    // action_log: function() {
    //   this.Refresh();
    // },

    DataItems: function() {
      this.isLoading = false;
    },
    filter: function() {
      this.isLoading = true;
    }
  },
  methods: {
    Refresh(search = false) {
      this.isLoading = true;
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter(key => predicate(obj[key]))
          .reduce((res, key) => ((res[key] = obj[key]), res), {});
      let query = this.is_local
        ? {
            limit: this.pagination.perPage - 1,
            offset: (this.pagination.currentPage - 1) * this.pagination.perPage
          }
        : {
            limit: this.pagination.perPage - 1,
            offset: search
              ? 0
              : (this.pagination.currentPage - 1) * this.pagination.perPage,
            search_text: this.search,
            order_by: this.sort.column ? this.sort.column : "id",
            order_direction: this.sort.asc ? "ASC" : "DESC",
            request_type: this.request_type,
            removed: this.removed,
            date_from: this.date_from,
            date_to: this.date_to,
            action_log: this.action_log,
            ...Object.filter(this.filter ? this.filter : {}, el => el != "")
          };
      this.$store.commit("SET_FILTER", this.filter);
      this.$emit("Refresh", query);
      this.progress_count = null;
      this.isLoading = false;
    },
    handleCommand(command) {
      if (command) command();
    },

    progress(e, file, list) {
      this.isLoading = true;
      this.progress_count = e.percent;
      this.isLoading = false;
    },
    //
    handleErrorUpload(error, file, fileList) {
      // console.log(error.status);
      // console.log(error.error);
      // const errors = JSON.stringify(error);
      // const errors2 = JSON.parse(error);
      // console.log(errors);
      // console.log(errors2);
      Swal.fire({
        text: `${error}`,
        type: "error",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.isLoading = false;
        }
        this.isLoading = false;
      });
    },
    selection_change(selections) {
      this.$emit("selection", selections);
    },
    sortChange(val) {
      this.sort.column = val.prop;
      this.sort.asc = val.order == "ascending" ? true : false;
      this.Refresh();
    },
    setLocalSort(val) {
      // console.log(val.prop, val.order);
      this.sort.column = val.prop;
      this.locallySortedData = [...this.pagedData].sort((a, b) => {
        if (val.order == "ascending") a[val.prop] - b[val.prop];
        if (val.order == "descending") b[val.prop] - a[val.prop];
      });
      console.log(this.locallySortedData);
      this.Refresh();
    },
    Export() {
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter(key => predicate(obj[key]))
          .reduce((res, key) => ((res[key] = obj[key]), res), {});
      let query = {
        search_text: this.search,
        ...Object.filter(this.filter, el => el != ""),
        removed: this.removed,
        date_from: this.date_from,
        date_to: this.date_to,
        action_log: this.action_log
      };
      this.$emit("export", query);
    },
    Export_Bulk() {
      this.$emit("Export_Bulk");
    },
    emitImportBulk() {
      this.$emit("addBulk");
    },
    resetFunction() {
      this.search = "";
      // this.date_from = "";
      // this.date_to = "";
      // this.action_log = "";
      this.removed = 0;
      this.Refresh(false);
      this.$emit("reset");
    }
  }
};
</script>

<style lang="scss">
.custom_expand {
  cursor: pointer !important;
}

.resetButton .el-button.is-round:focus,
.resetButton .el-button.is-round:hover,
.resetButton .el-button.is-round:active {
  border-color: #173f5f;
  color: #173f5f;
  color: #fff;
  background-color: #173f5f;
  opacity: 1;
}

.base_color {
  opacity: 0.9;
  color: #fff;
  background-color: #173f5f;
}

.resetHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0.5rem 0rem;

  .el-button {
    margin: 0rem 0.7rem;
  }

  div label {
    margin: 0rem 0.3rem;
  }
}

.resetButton {
  color: #fff;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: 1rem;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.el-table__row--striped {
  background-color: #fff !important;
}

.el-table--border,
.el-table--group {
  border: unset !important;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}

thead tr th .cell {
  border-bottom-width: 1px !important;
  font-size: 0.75rem !important;
  text-transform: uppercase !important;
  color: #9a9a9a !important;
  font-weight: 400 !important;
  padding-bottom: 5px !important;
}

.el-icon-arrow-right {
  color: #23ccef;
}
.main_data_table_pagination_container {
  .yes_yes {
    background: #000 !important;
  }
  .main_collapse_data_pagination_wrapping {
    padding: 1.1rem;
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 0.2rem;
    }
    .el-select-dropdown__list {
      padding: 16px 0 !important;
    }
  }

  // .el-select-dropdown__item {
  //   padding: 10px 20px !important;
  // }
}

.collapse_with_rtl_direction {
  .el-collapse-item__header {
    flex-direction: row-reverse;
    .wrapper_for_search_and_filter {
      flex-direction: row-reverse;
    }
  }
}

.table_with_rtl_direction {
  // background: #f00 !important;
  // padding: 40px;
  direction: rtl;
  .el-table__header-wrapper {
    direction: rtl;
  }
  .cell {
    text-align: right;
  }
}
.footer_with_rtl_direction {
  flex-direction: row-reverse;
  .handler_showing_filters_with_rtl {
    flex-direction: row-reverse;
  }
}
.tabs_for_contacts_with_rtl {
  // background: #ddd;
  direction: rtl;
  .el-tabs__nav {
    float: right;
  }
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__item {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      height: 0;
      background-color: #409eff;
      z-index: 1;
      width: 90%;
      bottom: 0;
      right: 0;
    }
    &.is-active {
      &::before {
        height: 2px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
// ::v-deep .el-select-dropdown__list {
// ::v-deep .el-select-dropdown__list {
// padding: 16px 0 !important;
//   background: #eee !important;
//   .el-select-dropdown__item {
//     background: #eee !important;
//   }
// }
.main_data_table_pagination_container {
  .data_table_pagination_footer {
    .showing_numbers_container {
      @media (min-width: 320px) and (max-width: 576px) {
        display: block !important;
        .card_numbers_text {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
</style>
